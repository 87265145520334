import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
import PanelNav from '../../components/PanelNav';
import ContentsList from '../../components/ContentsList';
import { Abemakun } from '@openameba/spindle-ui/Icon';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ブランド" mdxType="SEO" />
    <PageTitle title="ブランド" enTitle="Brand" mdxType="PageTitle" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8959951fe345e9cc718f4214c2c4d7ce/21b4d/brand-index.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA2UlEQVQoz6VRUQqCQBTsJh2gI3mHPsQLeJs+0iME9SlSqV+KJKikKKgrKCKuE7tkFJmgDTxmH7tvdh6zwgj6vudMCEEQBAjDEL7vo6qqj/sxrKYEi6JAlmWwbRtJkiwTZI+HgbqukaYpmqbhbhkvdjgMOY4DQRDgui7vKaWYwk+HrCzLgiRJkGUZoii+BGevzNC2LUzThKIo0DQNqqp+uZ8dSlmWyPOcn1k4i0N55ziOORtXg3/wV8pM4B5FIBWBftbh3bzlKXfPNHenPdbbDQ6XI+872k0KPgB0MAdXQXFMEwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8959951fe345e9cc718f4214c2c4d7ce/2aaaf/brand-index.webp 160w", "/static/8959951fe345e9cc718f4214c2c4d7ce/85e47/brand-index.webp 320w", "/static/8959951fe345e9cc718f4214c2c4d7ce/75198/brand-index.webp 640w", "/static/8959951fe345e9cc718f4214c2c4d7ce/691bc/brand-index.webp 960w", "/static/8959951fe345e9cc718f4214c2c4d7ce/223e5/brand-index.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8959951fe345e9cc718f4214c2c4d7ce/69538/brand-index.png 160w", "/static/8959951fe345e9cc718f4214c2c4d7ce/72799/brand-index.png 320w", "/static/8959951fe345e9cc718f4214c2c4d7ce/6af66/brand-index.png 640w", "/static/8959951fe345e9cc718f4214c2c4d7ce/d9199/brand-index.png 960w", "/static/8959951fe345e9cc718f4214c2c4d7ce/21b4d/brand-index.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8959951fe345e9cc718f4214c2c4d7ce/6af66/brand-index.png",
              "alt": "「Brand」と書かれた吹き出しが、右手を頭にかざしている人から出ているイラスト",
              "title": "「Brand」と書かれた吹き出しが、右手を頭にかざしている人から出ているイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`ブランドとは、様々な経験の蓄積の結果、人々の心の中に作られるイメージや期待のことです。それは、Amebaが発信するあらゆるコミュニケーションや制作物すべてを通して形成されていきます。したがって、Amebaブランドもすべての活動において、常にブランドを中心に据え発信される一貫した体験とそこから感じられる印象でコミュニケーションを図る必要があります。`}</p>
    {
      /* textlint-disable @openameba/ameba/no-doubled-joshi */
    }
    <ContentsList list={[{
      url: "/brand/about/",
      title: "ブランディングとは",
      enTitle: "About Branding",
      summary: "ブランディングとは企業や商品、サービスなどの\"らしさ\"を時代やニーズを捉えながら、ビジュアルや体験を通して社内外に一貫して届けていくことです。"
    }]} mdxType="ContentsList"></ContentsList>
    {
      /* textlint-disable @openameba/ameba/ja-no-mixed-period */
    }
    <PanelNav title="Amebaブランド" enTitle="Ameba Brand" icon={<Abemakun aria-hidden="true" mdxType="Abemakun" />} lead="Amebaのブランド = Amebaらしさをユーザーに届けるためには、まず「Amebaらしさ」が何であるかを理解しなければいけません。この項目では、Amebaが目指すところやその価値観について説明しています。" list={[{
      url: "/brand/ameba-brand/vision-and-mission/",
      name: "ビジョンとミッション",
      summary: "Amebaのビジョン（理想とする姿）、ミッション（存在意義、社会的意思）、バリュー（Visionを実現するための価値観）を定義しています。"
    }, {
      url: "/brand/ameba-brand/brand-guidelines/",
      name: "ブランドガイドライン",
      summary: "Amebaのブランドコア、ブランドパーソナリティからAmebaらしい声質やトーン、言葉選びをブランドボイスとして定義しています。"
    }, {
      url: "/brand/ameba-brand/slide-templates/",
      name: "スライドテンプレート",
      summary: "Amebaらしさとしてのグラフィックが盛り込まれたスライドテンプレートを掲載しています。"
    }, {
      url: "/brand/ameba-brand/resources/",
      name: "関連資料",
      summary: "ブランドに関する関連資料を掲載しています。"
    }]} mdxType="PanelNav"></PanelNav>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      